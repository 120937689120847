import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import { Button } from "./../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LabelField } from "./../../components/fields";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table, Thead, Tbody, Th, Tr, Td } from "./../../components/elements/Table";
import { Box, Text } from "./../../components/elements";
import Wallet from "../blocks/Wallet";
import ToggleButton from "react-toggle-button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Report from "../blocks/Report";

import { useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

var moment = require("moment-timezone");

export default function ProductList() {
  const { userData, token, selectedCompany } = useSelector((state) => state.counter);
  const [showSwal, setShowSwal] = useState(false);
  const [showSwal2, setShowSwal2] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Saving, setSaving] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [payment, setPayment] = useState("");
  const [room, setRoom] = useState(0);
  const [roomData, setRoomData] = useState([]);
  const [mode, setMode] = useState("add");
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerpage] = useState(20);
  const [totalRecod, setTotalRecod] = useState(0);
  const [data, setData] = useState({});
  const [id, setId] = useState("");
  const [detail, setDetail] = useState({});
  const [male, setMale] = useState(false);
  const [appointmentType, setFilterAppointmentType] = useState("");

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [timeslots, setTimeSlots] = useState([]);
  const [timeslots2, setTimeSlots2] = useState([]);

  useEffect(() => {
    fetchPatient();
    fetchData(1);
    fetchRoom();
  }, []);

  async function fetchRoom() {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      id: userData._id,
      company: selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/room/all?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setRoomData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function fetchData(pageNumber) {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const options = {
      headers: { authorization: token },
    };

    const params = {
      page: perPage * (pageNumber - 1),
      limit: perPage,
      patient: id,
      startdate: filterStartDate,
      enddate: filterEndDate,
      practitioner: userData._id,
      appointmentType: appointmentType,
      company: selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/user-appointment?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setTotalRecod(Response.data.totalCount);
        setData(Response.data.result);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function fetchPatient() {
    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const options = {
      headers: { authorization: token },
    };

    const params = {
      id: id,
      company: selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/patient/detail?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        setDetail(Response.data.result);
        if (Response.data.result.gender == "M") {
          setMale(true);
        } else {
          setMale(false);
        }
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  // On Pagination Change
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    fetchData(pageNumber);
  }

  // Soft Delete Record
  async function handleDelete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/appointment/delete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchPatient(1);
          fetchData(1);
          setActivePage(1);
          setShowSwal(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  async function handleComplete() {
    const options = {
      headers: { authorization: token },
    };

    try {
      axios.post(process.env.REACT_APP_API_URL + "app/appointment/complete/" + id, {}, options).then((response) => {
        if (response.data.status == "success") {
          toast(response.data.message);
          fetchPatient(1);
          fetchData(1);
          setActivePage(1);
          setShowSwal2(false);
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  }

  function addOneMinuteToTime(timeString) {
    // Parse the time string to create a Date object
    var date = new Date("2000-01-01 " + timeString);

    // Add one minute to the date
    date.setMinutes(date.getMinutes() + 1);

    // Format the result as "hh:mm AM/PM"
    var formattedTime = date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

    return formattedTime;
  }

  function formatDate(inputDate) {
    // Create a new Date object
    var date = new Date(inputDate);

    // Get the individual components of the date
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    var day = ("0" + date.getDate()).slice(-2);

    // Create the formatted date string
    var formattedDate = year + "-" + month + "-" + day;

    return formattedDate;
  }

  async function handleBooking() {
    if (Saving) {
      return;
    }

    if (room == 0 && roomData.length > 0) {
      toast.error("Select room");
      return;
    }

    if (!appointmentDate && appointmentDate == "") {
      toast.error("Enter appointment date");
      return;
    }

    if (!startTime && startTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!endTime && endTime == "") {
      toast.error("Enter appointment start time");
      return;
    }

    if (!payment && payment == "") {
      toast.error("Enter appointment payment");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    if (mode == "add") {
      const urlString = window.location.search;
      const urlParams = new URLSearchParams(urlString);
      const id = urlParams.get("id");

      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/book",
        {
          appointmentDate: formatDate(appointmentDate),
          startTime: addOneMinuteToTime(startTime),
          endTime: addOneMinuteToTime(endTime),
          payment: payment,
          practitioner: userData._id,
          patient: id,
          room: room,
          company: selectedCompany ? selectedCompany._id : "",
        },
        options
      );
      if (Response.data.status == "success") {
        setAppointmentDate("");
        setStartTime("");
        setEndTime("");
        setPayment("");

        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchPatient(1);
        fetchData(1);
        setActivePage(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        //setShow(false);
      }
    } else {
      const urlString = window.location.search;
      const urlParams = new URLSearchParams(urlString);
      const userid = urlParams.get("id");

      const Response = await axios.post(
        process.env.REACT_APP_API_URL + "app/appointment/update/" + id,
        {
          appointmentDate: formatDate(appointmentDate),
          startTime: addOneMinuteToTime(startTime),
          endTime: addOneMinuteToTime(endTime),
          payment: payment,
          practitioner: userData._id,
          patient: userid,
          room: room,
        },
        options
      );
      if (Response.data.status == "success") {
        setAppointmentDate("");
        setStartTime("");
        setEndTime("");
        setPayment("");
        setSaving(false);
        toast.success(Response.data.message);
        setShow(false);
        fetchPatient(1);
        fetchData(1);
        setActivePage(1);
      } else {
        setSaving(false);
        toast.error(Response.data.message);
        // setShow(false);
      }
    }
  }

  const CheckboxList = ({ sensations }) => {
    return (
      <>
        {sensations &&
          Object.entries(sensations).map(([sensation, isChecked]) => (
            <div className="col-md-4" key={sensation}>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" checked={isChecked} />
                <label className="form-check-label">{sensation.charAt(0).toUpperCase() + sensation.slice(1)}</label>
              </div>
            </div>
          ))}
      </>
    );
  };

  function convertTimestampToAMPM(timestamp) {
    // Create a new Date object in UTC
    var date = new Date(timestamp);
    date.setMinutes(date.getMinutes() - 1);

    var utcHours = date.getUTCHours();
    var utcMinutes = date.getUTCMinutes();
    var utcSeconds = date.getUTCSeconds();

    // Convert to AM/PM format
    var ampm = utcHours >= 12 ? "PM" : "AM";

    // Adjust hours for AM/PM format
    utcHours = utcHours % 12;
    utcHours = utcHours ? utcHours : 12; // The hour '0' should be '12' in AM/PM

    // Add leading zeros to minutes and seconds
    utcMinutes = utcMinutes < 10 ? "0" + utcMinutes : utcMinutes;
    utcSeconds = utcSeconds < 10 ? "0" + utcSeconds : utcSeconds;

    // Create a formatted string
    var formattedTime = utcHours + ":" + utcMinutes + " " + ampm;

    return formattedTime;
  }

  async function setAppointmentType(value, id) {
    setSaving(true);
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/appointment/update-type/" + id,
      {
        type: value,
      },
      options
    );
    if (Response.data.status == "success") {
      toast.success("Appointment type updated");
    } else {
      setSaving(false);
      toast.error(Response.data.message);
      // setShow(false);
    }
  }

  const selectedDay = async () => {
    if (appointmentDate == "") {
      toast.error("Select date");
      return;
    }

    const isoDate = new Date(appointmentDate);
    const year = isoDate.getFullYear();
    const month = String(isoDate.getMonth() + 1).padStart(2, "0");
    const day = String(isoDate.getDate()).padStart(2, "0");
    const ymdFormat = `${year}-${month}-${day}`;

    setLoading1(true);
    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/home/check-availability", {
      date: ymdFormat,
      practitioner: userData._id,
      time: 15,
      current: id,
    });

    if (Response.data.status === "success") {
      setTimeSlots(Response.data.data);
      setLoading1(false);
    } else {
      setLoading1(false);
    }
  };

  const getEndDate = async (startTime) => {
    const isoDate = new Date(appointmentDate);
    const year = isoDate.getFullYear();
    const month = String(isoDate.getMonth() + 1).padStart(2, "0");
    const day = String(isoDate.getDate()).padStart(2, "0");
    const ymdFormat = `${year}-${month}-${day}`;

    setLoading2(true);

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/home/check-availability-end", {
      date: ymdFormat,
      practitioner: userData._id,
      time: 15,
      startTime: startTime,
      current: id,
    });

    if (Response.data.status === "success") {
      setTimeSlots2(Response.data.data);
      setLoading2(false);
    } else {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (appointmentDate != "") {
      selectedDay();
    }
  }, [appointmentDate]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <h4 className="mb-4">Details of {detail.name}</h4>
            <Tabs defaultActiveKey="details" className="mb-3">
              <Tab eventKey="details" title="Details">
                <div className="row">
                  <div className="col-md-12 patient_details">
                    <Tabs defaultActiveKey="Personal" className="mb-3" fill variant="pills">
                      <Tab eventKey="Personal" title="Personal">
                        <Row>
                          <Col xl={4}>
                            <LabelField label="name" type="text" fieldSize="w-100 h-md" value={detail.name} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="email  " type="email" fieldSize="w-100 h-md" value={detail.email} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="phone  " type="text" fieldSize="w-100 h-md" value={detail.phone} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <div class="mc-label-field-group label-col">
                              <label class="mc-label-field-title">Gender </label>
                              <select class="mc-label-field-input w-100 h-md" value={detail.gender} readOnly={true} disable={true}>
                                <option value={""}> Select </option>
                                <option value={"M"}> Male </option>
                                <option value={"F"}> Female </option>
                              </select>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <LabelField label="date of birth " type="text" fieldSize="w-100 h-md" value={moment(detail.dob).format("MM-DD-YYYY")} readOnly={true} disable={true} pattern="\d{4}-\d{2}-\d{2}" />
                          </Col>

                          <Col xl={12}>
                            <LabelField label="Street address (Optional)" type="text" fieldSize="w-100 h-md" value={detail.street} />
                          </Col>

                          <Col xl={12}>
                            <LabelField label="State (Optional)" type="text" fieldSize="w-100 h-md" value={detail.state} />
                          </Col>

                          <Col xl={12}>
                            <LabelField label="zip (Optional)" type="text" fieldSize="w-100 h-md" value={detail.zip} />
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="Emergency" title="Emergency">
                        <Row>
                          <Col xl={4}>
                            <LabelField label="Emergency contact name" type="text" fieldSize="w-100 h-md" value={detail.emergency_contact_name} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Emergency contact phone" type="number" fieldSize="w-100 h-md" value={detail.emergency_contact_phone} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Emergency contact relationship with you" type="text" fieldSize="w-100 h-md" value={detail.emergency_contact_relation} readOnly={true} disable={true} />
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="Primary" title={"Primary Care"}>
                        <Row>
                          <Col xl={4}>
                            <LabelField label="Physician's Name" type="text" fieldSize="w-100 h-md" value={detail.primary_care_physician_name} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Physician's Phone" type="number" fieldSize="w-100 h-md" value={detail.primary_care_physician_phone} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={4}>
                            <LabelField label="Physician's Email" type="email" fieldSize="w-100 h-md" value={detail.primary_care_physician_email} readOnly={true} disable={true} />
                          </Col>

                          <Col xl={12}>
                            <div class="mc-label-field-group label-col">
                              <label class="mc-label-field-title"> Physician's Adddress</label>
                              <textarea placeholder="Type here..." class="mc-label-field-input w-100 h-md" value={detail.primary_care_physician_address} readOnly={true} disable={true}></textarea>
                            </div>
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="Record" title="Health Record">
                        <Row className="mt-3">
                          <div className="col-md-12">I feel the following physical sensations. Check all that apply.</div>
                          <div className="row">
                            <CheckboxList sensations={detail.physicalSensations} />
                          </div>

                          <hr />

                          <div className="col-md-12">As a result of the physical sensations, they are causing me to be mentally:</div>
                          <div className="row">
                            <CheckboxList sensations={detail.mentalSensations} />
                          </div>

                          <hr />

                          <Col xl={9}>Do you have past or current injuries?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.pastInjuries} />
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col xl={9}>Do you have difficult laying on your front, back, or side?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.difficultLaying} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you have an allergy to oils, lotions, or ointments?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.allergy} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you wear contact lenses?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.contactLenses} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you wear dentures?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.dentures} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you wear hearing aids?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.hearingAids} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you currently participate in resistance (weight) training routine?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.weightTraining} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={9}>Do you currently participate in a stretch training routine?</Col>
                          <Col xl={3}>
                            <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={detail.stretchTraining} />
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={12}>I am participating in the following activities to help me feel better?</Col>
                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.how_i_see_this} />
                              <label class="form-check-label">How I see things</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.how_i_feel} />
                              <label class="form-check-label">How I feel</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.what_i_belive} />
                              <label class="form-check-label">What I Believe</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.my_relationships} />
                              <label class="form-check-label">My Relationships</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.event_attending} />
                              <label class="form-check-label">Event attending</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.resources} />
                              <label class="form-check-label">Resources</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.related_information} />
                              <label class="form-check-label">Related Information</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.time} />
                              <label class="form-check-label">Time</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.work} />
                              <label class="form-check-label">Work</label>
                            </div>
                          </Col>

                          <Col xl={4}>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" checked={detail.activities?.other} />
                              <label class="form-check-label">Other</label>
                            </div>
                          </Col>
                        </Row>

                        <hr />
                        <Row>
                          <Col xl={12}>What goals do you want to achieve through these sessions?</Col>
                          <Col xl={12}>
                            <textarea class="mc-label-field-input w-100 h-md" value={detail.goal} readOnly={true} disable={true}></textarea>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col xl={12}>Is there any other significant information about your physical or mental health that your D- Practitioner should know?</Col>
                          <Col xl={12}>
                            <textarea class="mc-label-field-input w-100 h-md" value={detail.otherInfo} readOnly={true} disable={true}></textarea>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Tab>

              {selectedCompany && selectedCompany.isOwner && (
                <Tab eventKey="appointment" title="Appointment">
                  <CardLayout className={"mt-3"}>
                    <Row>
                      <Col xl={4}>
                        <div className="form-group">
                          <label>Start date</label>
                          <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="Start date" className="form-control" selected={filterStartDate} onChange={(date) => setFilterStartDate(date)} dateFormat="MM/dd/yyyy" />
                        </div>
                      </Col>

                      <Col xl={4}>
                        <div className="form-group">
                          <label>End date</label>
                          <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="End date" className="form-control" selected={filterEndDate} onChange={(date) => setFilterEndDate(date)} dateFormat="MM/dd/yyyy" />
                        </div>
                      </Col>

                      <Col xl={4}>
                        <div className="form-group">
                          <label>Appointment type</label>
                          <select className="form-control" value={appointmentType} onChange={(e) => setFilterAppointmentType(e.target.value)}>
                            <option value=""> - All - </option>
                            <option value="Swedish Massage"> Swedish Massage </option>
                            <option value="Deep Tissue Massage"> Deep Tissue Massage </option>
                            <option value="Thai Massage"> Thai Massage </option>
                            <option value="Shiatsu"> Shiatsu </option>
                            <option value="Sports Massage"> Sports Massage </option>
                            <option value="DHST Training"> DHST Training </option>
                            <option value="DHST Treatment"> DHST Treatment </option>
                          </select>
                        </div>
                      </Col>

                      <Col xl={4}>
                        <Button
                          icon={"search"}
                          text={"Search"}
                          href={"#"}
                          className="mc-btn primary w-100"
                          onClick={() => {
                            setActivePage(1);
                            fetchData(1);
                          }}
                        />
                      </Col>

                      {selectedCompany && selectedCompany.practitioner == userData._id && (
                        <>
                          <Col xl={4}>
                            <Button
                              icon={"today"}
                              text={"Add appointment"}
                              href={"#"}
                              className="mc-btn primary w-100"
                              onClick={() => {
                                setShow(true);
                                setMode("add");
                                setStartTime("");
                                setEndTime("");
                              }}
                            />
                          </Col>
                        </>
                      )}

                      {selectedCompany && selectedCompany.practitioner != userData._id && userData.role && userData.role.access.appointments.add && (
                        <>
                          <Col xl={4}>
                            <Button
                              icon={"today"}
                              text={"Add appointment."}
                              href={"#"}
                              className="mc-btn primary w-100"
                              onClick={() => {
                                setMode("add");
                                setShow(true);
                                setStartTime("");
                                setEndTime("");
                              }}
                            />
                          </Col>
                        </>
                      )}

                      {!selectedCompany && (
                        <>
                          <Col xl={4}>
                            <Button
                              icon={"today"}
                              text={"Add appointment"}
                              href={"#"}
                              className="mc-btn primary w-100"
                              onClick={() => {
                                setMode("add");
                                setShow(true);
                                setStartTime("");
                                setEndTime("");
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    <br />
                    <Box className="table-responsive">
                      <Table className="table">
                        <Thead className=" ">
                          <Tr>
                            <Th># ID</Th>
                            <Th>Date/Time</Th>
                            <Th>Room</Th>
                            <Th>Type</Th>
                            <Th>Payment</Th>
                            <Th>Complete</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody className="">
                          {data?.length > 0 &&
                            data.map((item, key) => (
                              <Tr key={key}>
                                <Td>
                                  {item.uniqueId}{" "}
                                  {item.cancelled == 1 && (
                                    <>
                                      -{" "}
                                      <span className="bg-danger p-1 rounded text-white">
                                        <small>Cancalled</small>
                                      </span>
                                    </>
                                  )}
                                </Td>
                                <Td>
                                  {moment(item.appointmentDate).format("MM/DD/YYYY")} <br />
                                  {convertTimestampToAMPM(item.startTime * 1000)} - {convertTimestampToAMPM(item.endTime * 1000)}
                                </Td>
                                <Td>{item.room && item.room.name}</Td>
                                <Td>
                                  <select className="form-control" defaultValue={item.type} onChange={(e) => setAppointmentType(e.target.value, item._id)}>
                                    <option value=""> None </option>
                                    <option value="Swedish Massage"> Swedish Massage </option>
                                    <option value="Deep Tissue Massage"> Deep Tissue Massage </option>
                                    <option value="Thai Massage"> Thai Massage </option>
                                    <option value="Shiatsu"> Shiatsu </option>
                                    <option value="Sports Massage"> Sports Massage </option>
                                    <option value="DHST Training"> DHST Training </option>
                                    <option value="DHST Treatment"> DHST Treatment </option>
                                  </select>
                                </Td>
                                <Td>{item.payment.replace("_", " ")}</Td>
                                <Td>{item.isOpen ? <span className="bg-warning p-1 rounded">No</span> : <span className="bg-success p-1 rounded text-white">Yes</span>}</Td>
                                <Td>
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" size="sm">
                                      Action
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {selectedCompany && selectedCompany.practitioner == userData._id && (
                                        <>
                                          {item.isOpen && (
                                            <>
                                              {item.cancelled == 0 && (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setMode("edit");
                                                    setId(item._id);

                                                    setStartTime(convertTimestampToAMPM(new Date(item.startTime * 1000)));
                                                    setEndTime(convertTimestampToAMPM(new Date(item.endTime * 1000)));
                                                    setAppointmentDate(new Date(moment(item.appointmentDate).format("MM/DD/YYYY")));

                                                    setPayment(item.payment);
                                                    if (item?.room) {
                                                      setRoom(item?.room?._id);
                                                    }

                                                    setShow(true);
                                                  }}
                                                >
                                                  Reschedule
                                                </Dropdown.Item>
                                              )}
                                            </>
                                          )}

                                          {item.isOpen && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                setId(item._id);
                                                setShowSwal2(true);
                                              }}
                                            >
                                              Mark Complete
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item
                                            onClick={() => {
                                              setId(item._id);
                                              setShowSwal(true);
                                            }}
                                          >
                                            Cancel
                                          </Dropdown.Item>
                                        </>
                                      )}
                                      {selectedCompany && selectedCompany.practitioner != userData._id && userData.role && userData.role.access.appointments.edit && (
                                        <>
                                          {item.isOpen && (
                                            <>
                                              {item.cancelled == 0 && (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setMode("edit");
                                                    setId(item._id);

                                                    setStartTime(convertTimestampToAMPM(new Date(item.startTime * 1000)));
                                                    setEndTime(convertTimestampToAMPM(new Date(item.endTime * 1000)));
                                                    setAppointmentDate(new Date(moment(item.appointmentDate).format("MM/DD/YYYY")));

                                                    setPayment(item.payment);
                                                    if (item.room) {
                                                      setRoom(item.room._id);
                                                    }

                                                    setShow(true);
                                                  }}
                                                >
                                                  Reschedule
                                                </Dropdown.Item>
                                              )}
                                            </>
                                          )}

                                          {item.isOpen && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                setId(item._id);
                                                setShowSwal2(true);
                                              }}
                                            >
                                              Mark Complete
                                            </Dropdown.Item>
                                          )}
                                        </>
                                      )}
                                      {selectedCompany && selectedCompany.practitioner != userData._id && userData.role && userData.role.access.appointments.delete && (
                                        <>
                                          {item.isOpen && (
                                            <>
                                              {item.cancelled == 0 && (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setMode("edit");
                                                    setId(item._id);

                                                    setStartTime(convertTimestampToAMPM(new Date(item.startTime * 1000)));
                                                    setEndTime(convertTimestampToAMPM(new Date(item.endTime * 1000)));
                                                    setAppointmentDate(new Date(moment(item.appointmentDate).format("MM/DD/YYYY")));

                                                    setPayment(item.payment);
                                                    if (item.room) {
                                                      setRoom(item.room._id);
                                                    }

                                                    setShow(true);
                                                  }}
                                                >
                                                  Reschedule
                                                </Dropdown.Item>
                                              )}
                                            </>
                                          )}

                                          {item.isOpen && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                setId(item._id);
                                                setShowSwal2(true);
                                              }}
                                            >
                                              Mark Complete
                                            </Dropdown.Item>
                                          )}
                                        </>
                                      )}
                                      {!selectedCompany && (
                                        <>
                                          {item.isOpen && (
                                            <>
                                              {item.cancelled == 0 && (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setMode("edit");
                                                    setId(item._id);

                                                    setStartTime(convertTimestampToAMPM(new Date(item.startTime * 1000)));
                                                    setEndTime(convertTimestampToAMPM(new Date(item.endTime * 1000)));
                                                    setAppointmentDate(new Date(moment(item.appointmentDate).format("MM/DD/YYYY")));

                                                    setPayment(item.payment);

                                                    if (item.room) {
                                                      setRoom(item.room._id);
                                                    }

                                                    setShow(true);
                                                  }}
                                                >
                                                  Reschedule
                                                </Dropdown.Item>
                                              )}
                                            </>
                                          )}

                                          {item.isOpen && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                setId(item._id);
                                                setShowSwal2(true);
                                              }}
                                            >
                                              Mark Complete
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item
                                            onClick={() => {
                                              setId(item._id);
                                              setShowSwal(true);
                                            }}
                                          >
                                            Cancel
                                          </Dropdown.Item>
                                        </>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </Box>
                    <Box className="mc-paginate">
                      <Text className="mc-paginate-title">
                        Total <b>{totalRecod}</b> Results Found
                      </Text>
                      <nav aria-label="Page navigation example">
                        {totalRecod > perPage ? (
                          <Pagination
                            prevPageText="prev"
                            nextPageText="next"
                            firstPageText="first"
                            lastPageText="last"
                            activePage={activePage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalRecod}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                          />
                        ) : (
                          ""
                        )}
                      </nav>
                    </Box>
                  </CardLayout>
                </Tab>
              )}
              {selectedCompany && !selectedCompany.isOwner && userData.role && userData.role.access.clients.view && (
                <Tab eventKey="appointment" title="Appointment">
                  <CardLayout className={"mt-3"}>
                    <Row>
                      <Col xl={4}>
                        <div className="form-group">
                          <label>Start date</label>
                          <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="Start date" className="form-control" selected={filterStartDate} onChange={(date) => setFilterStartDate(date)} dateFormat="MM/dd/yyyy" />
                        </div>
                      </Col>

                      <Col xl={4}>
                        <div className="form-group">
                          <label>End date</label>
                          <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="End date" className="form-control" selected={filterEndDate} onChange={(date) => setFilterEndDate(date)} dateFormat="MM/dd/yyyy" />
                        </div>
                      </Col>

                      <Col xl={4}>
                        <div className="form-group">
                          <label>Appointment type</label>
                          <select className="form-control" value={appointmentType} onChange={(e) => setFilterAppointmentType(e.target.value)}>
                            <option value=""> - All - </option>
                            <option value="Swedish Massage"> Swedish Massage </option>
                            <option value="Deep Tissue Massage"> Deep Tissue Massage </option>
                            <option value="Thai Massage"> Thai Massage </option>
                            <option value="Shiatsu"> Shiatsu </option>
                            <option value="Sports Massage"> Sports Massage </option>
                            <option value="DHST Training"> DHST Training </option>
                            <option value="DHST Treatment"> DHST Treatment </option>
                          </select>
                        </div>
                      </Col>

                      <Col xl={4}>
                        <Button
                          icon={"search"}
                          text={"Search"}
                          href={"#"}
                          className="mc-btn primary w-100"
                          onClick={() => {
                            setActivePage(1);
                            fetchData(1);
                          }}
                        />
                      </Col>

                      <Col xl={4}>
                        <Button
                          icon={"today"}
                          text={"Add appointment"}
                          href={"#"}
                          className="mc-btn primary w-100"
                          onClick={() => {
                            setMode("add");
                            setShow(true);
                            setStartTime("");
                            setEndTime("");
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Box className=" table-responsive">
                      <Table className="table">
                        <Thead className=" ">
                          <Tr>
                            <Th># ID</Th>
                            <Th>Date/Time</Th>
                            <Th>Room</Th>
                            <Th>Type</Th>
                            <Th>Payment</Th>
                            <Th>Complete</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody className="">
                          {data?.length > 0 &&
                            data.map((item, key) => (
                              <Tr key={key}>
                                <Td>
                                  {item.uniqueId}{" "}
                                  {item.cancelled == 1 && (
                                    <>
                                      -{" "}
                                      <span className="bg-danger p-1 rounded text-white">
                                        <small>Cancalled</small>
                                      </span>
                                    </>
                                  )}
                                </Td>
                                <Td>
                                  {moment(item.appointmentDate).format("MM/DD/YYYY")} <br />
                                  {convertTimestampToAMPM(item.startTime * 1000)} - {convertTimestampToAMPM(item.endTime * 1000)}
                                </Td>
                                <Td>{item.room && item.room.name}</Td>
                                <Td>
                                  <select className="form-control" defaultValue={item.type} onChange={(e) => setAppointmentType(e.target.value, item._id)}>
                                    <option value=""> None </option>
                                    <option value="Swedish Massage"> Swedish Massage </option>
                                    <option value="Deep Tissue Massage"> Deep Tissue Massage </option>
                                    <option value="Thai Massage"> Thai Massage </option>
                                    <option value="Shiatsu"> Shiatsu </option>
                                    <option value="Sports Massage"> Sports Massage </option>
                                    <option value="DHST Training"> DHST Training </option>
                                    <option value="DHST Treatment"> DHST Treatment </option>
                                  </select>
                                </Td>
                                <Td>{item.payment.replace("_", " ")}</Td>
                                <Td>{item.isOpen ? <span className="bg-warning p-1 rounded">No</span> : <span className="bg-success p-1 rounded text-white">Yes</span>}</Td>
                                <Td>
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" size="sm">
                                      Action
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {item.isOpen && (
                                        <>
                                          {item.cancelled == 0 && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                setMode("edit");
                                                setId(item._id);

                                                setStartTime(convertTimestampToAMPM(new Date(item.startTime * 1000)));
                                                setEndTime(convertTimestampToAMPM(new Date(item.endTime * 1000)));
                                                setAppointmentDate(new Date(moment(item.appointmentDate).format("MM/DD/YYYY")));

                                                setPayment(item.payment);

                                                if (item.room) {
                                                  setRoom(item.room._id);
                                                }

                                                setShow(true);
                                              }}
                                            >
                                              Reschedule
                                            </Dropdown.Item>
                                          )}
                                        </>
                                      )}

                                      {item.isOpen && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            setId(item._id);
                                            setShowSwal2(true);
                                          }}
                                        >
                                          Mark Complete
                                        </Dropdown.Item>
                                      )}

                                      <Dropdown.Item
                                        onClick={() => {
                                          setId(item._id);
                                          setShowSwal(true);
                                        }}
                                      >
                                        Cancel
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </Box>
                    <Box className="mc-paginate">
                      <Text className="mc-paginate-title">
                        Total <b>{totalRecod}</b> Results Found
                      </Text>
                      <nav aria-label="Page navigation example">
                        {totalRecod > perPage ? (
                          <Pagination
                            prevPageText="prev"
                            nextPageText="next"
                            firstPageText="first"
                            lastPageText="last"
                            activePage={activePage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalRecod}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                          />
                        ) : (
                          ""
                        )}
                      </nav>
                    </Box>
                  </CardLayout>
                </Tab>
              )}
              {!selectedCompany && (
                <Tab eventKey="appointment" title="Appointment">
                  <CardLayout className={"mt-3"}>
                    <Row>
                      <Col xl={4}>
                        <div className="form-group">
                          <label>Start date</label>
                          <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="Start date" className="form-control" selected={filterStartDate} onChange={(date) => setFilterStartDate(date)} dateFormat="MM/dd/yyyy" />
                        </div>
                      </Col>

                      <Col xl={4}>
                        <div className="form-group">
                          <label>End date</label>
                          <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" placeholderText="End date" className="form-control" selected={filterEndDate} onChange={(date) => setFilterEndDate(date)} dateFormat="MM/dd/yyyy" />
                        </div>
                      </Col>

                      <Col xl={4}>
                        <div className="form-group">
                          <label>Appointment type</label>
                          <select className="form-control" value={appointmentType} onChange={(e) => setFilterAppointmentType(e.target.value)}>
                            <option value=""> - All - </option>
                            <option value="Swedish Massage"> Swedish Massage </option>
                            <option value="Deep Tissue Massage"> Deep Tissue Massage </option>
                            <option value="Thai Massage"> Thai Massage </option>
                            <option value="Shiatsu"> Shiatsu </option>
                            <option value="Sports Massage"> Sports Massage </option>
                            <option value="DHST Training"> DHST Training </option>
                            <option value="DHST Treatment"> DHST Treatment </option>
                          </select>
                        </div>
                      </Col>

                      <Col xl={4}>
                        <Button
                          icon={"search"}
                          text={"Search"}
                          href={"#"}
                          className="mc-btn primary w-100"
                          onClick={() => {
                            setActivePage(1);
                            fetchData(1);
                          }}
                        />
                      </Col>

                      <Col xl={4}>
                        <Button
                          icon={"today"}
                          text={"Add appointment"}
                          href={"#"}
                          className="mc-btn primary w-100"
                          onClick={() => {
                            setMode("add");
                            setShow(true);
                            setStartTime("");
                            setEndTime("");
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Box className=" table-responsive">
                      <Table className="table">
                        <Thead className=" ">
                          <Tr>
                            <Th># ID</Th>
                            <Th>Date/Time</Th>
                            <Th>Room</Th>
                            <Th>Type</Th>
                            <Th>Payment</Th>
                            <Th>Complete</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody className="">
                          {data?.length > 0 &&
                            data.map((item, key) => (
                              <Tr key={key}>
                                <Td>
                                  {item.uniqueId}{" "}
                                  {item.cancelled == 1 && (
                                    <>
                                      -{" "}
                                      <span className="bg-danger p-1 rounded text-white">
                                        <small>Cancalled</small>
                                      </span>
                                    </>
                                  )}
                                </Td>
                                <Td>
                                  {moment(item.appointmentDate).format("MM/DD/YYYY")} <br />
                                  {convertTimestampToAMPM(item.startTime * 1000)} - {convertTimestampToAMPM(item.endTime * 1000)}
                                </Td>
                                <Td>{item.room && item.room.name}</Td>
                                <Td>
                                  <select className="form-control" defaultValue={item.type} onChange={(e) => setAppointmentType(e.target.value, item._id)}>
                                    <option value=""> None </option>
                                    <option value="Deep Tissue Massage"> Deep Tissue Massage </option>
                                    <option value="Swedish Massage"> Swedish Massage </option>
                                    <option value="DHST Treatment"> DHST Treatment </option>
                                    <option value="Sports Massage"> Sports Massage </option>
                                    <option value="DHST Training"> DHST Training </option>
                                    <option value="Thai Massage"> Thai Massage </option>
                                    <option value="Thai Massage"> Form Rolling </option>
                                    <option value="Stretching"> Stretching </option>
                                    <option value="Shiatsu"> Shiatsu </option>
                                    <option value="Other 1"> Other 1 </option>
                                    <option value="Other 2"> Other 2 </option>
                                    <option value="Other 3"> Other 3 </option>
                                  </select>
                                </Td>
                                <Td>{item.payment.replace("_", " ")}</Td>
                                <Td>{item.isOpen ? <span className="bg-warning p-1 rounded">No</span> : <span className="bg-success p-1 rounded text-white">Yes</span>}</Td>
                                <Td>
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" size="sm">
                                      Action
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {item.isOpen && (
                                        <>
                                          {item.cancelled == 0 && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                setMode("edit");
                                                setId(item._id);

                                                setStartTime(convertTimestampToAMPM(new Date(item.startTime * 1000)));
                                                setEndTime(convertTimestampToAMPM(new Date(item.endTime * 1000)));
                                                setAppointmentDate(new Date(moment(item.appointmentDate).format("MM/DD/YYYY")));

                                                setPayment(item.payment);
                                                if (item.room) {
                                                  setRoom(item.room._id);
                                                }

                                                setShow(true);
                                              }}
                                            >
                                              Reschedule
                                            </Dropdown.Item>
                                          )}
                                        </>
                                      )}

                                      {item.isOpen && (
                                        <Dropdown.Item
                                          onClick={() => {
                                            setId(item._id);
                                            setShowSwal2(true);
                                          }}
                                        >
                                          Mark Complete
                                        </Dropdown.Item>
                                      )}
                                      {/* {item.cancelled == 0 && moment(item.appointmentDate) > moment() && ( */}
                                      <Dropdown.Item
                                        onClick={() => {
                                          setId(item._id);
                                          setShowSwal(true);
                                        }}
                                      >
                                        Cancel
                                      </Dropdown.Item>
                                      {/* )} */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </Box>
                    <Box className="mc-paginate">
                      <Text className="mc-paginate-title">
                        Total <b>{totalRecod}</b> Results Found
                      </Text>
                      <nav aria-label="Page navigation example">
                        {totalRecod > perPage ? (
                          <Pagination
                            prevPageText="prev"
                            nextPageText="next"
                            firstPageText="first"
                            lastPageText="last"
                            activePage={activePage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalRecod}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center"
                          />
                        ) : (
                          ""
                        )}
                      </nav>
                    </Box>
                  </CardLayout>
                </Tab>
              )}

              <Tab eventKey="wallet" title={" Wallet (Balance: " + detail.balance + " min.)"}>
                <Wallet />
              </Tab>
              <Tab eventKey="reports" title="Reports">
                <Report male={male} />
              </Tab>
            </Tabs>
          </CardLayout>
        </Col>
      </Row>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{mode == "add" ? "Add" : "Edit"} appointment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <div className="form-group">
                <label class="mc-label-field-title">Select a room</label>
                <select className="form-control" value={room} onChange={(e) => setRoom(e.target.value)}>
                  <option value={"0"}> Select</option>
                  {roomData?.length > 0 && roomData.map((room, key) => <option value={room._id}> {room.name} </option>)}
                </select>
              </div>
            </Col>
            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">appointment date</label>
                <DatePicker minDate={new Date()} placeholderText="Start date" className="mc-label-field-input w-100 h-md" selected={appointmentDate} onChange={(date) => setAppointmentDate(date)} dateFormat="MM/dd/yyyy" />
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Appointment start time </label>
                {loading1 ? (
                  <div>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : (
                  <select
                    className="form-control"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      getEndDate(e.target.value);
                      setEndTime("");
                    }}
                  >
                    <option value={"0"}> Select</option>
                    {startTime != "" && <option value={startTime}> {startTime} </option>}

                    {timeslots.map(
                      (item, index) =>
                        timeslots[index + 1] &&
                        item.isAvailable && (
                          <option key={index} value={moment(item.time, "HH:mm").format("h:mm A")}>
                            {moment(item.time, "HH:mm").format("h:mm A")}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title"> Appointment end time </label>
                {loading2 ? (
                  <div>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : (
                  <select
                    className="form-control"
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  >
                    <option value={"0"}> Select</option>
                    {endTime != "" && <option value={endTime}> {endTime} </option>}

                    {timeslots2.map(
                      (item, index) =>
                        timeslots[index + 1] &&
                        item.isAvailable && (
                          <option key={index} value={moment(item.time, "HH:mm").format("h:mm A")}>
                            {moment(item.time, "HH:mm").format("h:mm A")}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>
            </Col>

            <Col xl={12}>
              <div class="mc-label-field-group label-col">
                <label class="mc-label-field-title">Payment</label>
                <select class="mc-label-field-input w-100 h-md" value={payment} onChange={(e) => setPayment(e.target.value)}>
                  <option value={""}> Select </option>
                  <option value={"wallet"}> Use wallet balance </option>
                  <option value={"prepaid_cash"}> Prepaid by cash </option>
                  <option value={"prepaid_card"}> Prepaid by card </option>
                  <option value={"pending"}> Not paid </option>
                </select>
              </div>
            </Col>

            <Col xl={12}>
              <Button className="mc-btn primary w-100" icon={!Saving && "verified"} type="button" disable={!Saving} onClick={() => handleBooking()}>
                {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Check & Submit</>}
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      {showSwal && (
        <SweetAlert warning showCancel confirmBtnText="Yes, delete it!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleDelete} onCancel={() => setShowSwal(false)}>
          This data will be permanently deleted.
        </SweetAlert>
      )}

      {showSwal2 && (
        <SweetAlert warning showCancel confirmBtnText="Yes, mark complete!" confirmBtnBsStyle="danger" cancelBtnBsStyle="primary" title="Are you sure?" onConfirm={handleComplete} onCancel={() => setShowSwal2(false)}>
          This can not be revised.
        </SweetAlert>
      )}
    </PageLayout>
  );
}
