import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { setSaveIndicator, setPhysicalSensation, setMentalSensation, setSelectedAppointment } from "../../actions";
import { useDispatch } from "react-redux";
export default function ProductList() {
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [initial, setInitial] = useState(true);
  const { userData, token, refreshToken, selectedAppointmentId } = useSelector((state) => state.counter);

  const [physicalSensations, setPhysicalSensations] = useState({
    tenderness: false,
    tightness: false,
    stiffness: false,
    cramping: false,
    throbbing: false,
    tingling: false,
    aching: false,
    soreness: false,
    constriction: false,
    hurting: false,
    burning: false,
    excruciating: false,
    sharp: false,
    shooting: false,
    unbearable: false,
    heavy: false,
    intense: false,
    extreme: false,
    hot: false,
    awful: false,
    severe: false,
    agonizing: false,
    nagging: false,
    itchy: false,
    dull: false,
    constant: false,
    prickly: false,
    pounding: false,
    radiating: false,
    other: false,
  });

  const [mentalSensations, setMentalSensations] = useState({
    angry: false,
    anxious: false,
    thrilled: false,
    admiration: false,
    agreeable: false,
    depressed: false,
    vulnerable: false,
    smiling: false,
    irritable: false,
    moody: false,
    appreciative: false,
    attractive: false,
    benevolent: false,
    light: false,

    serenity: false,
    pride: false,
    withdrawn: false,
    worried: false,
    pleasure: false,
    gratitude: false,
    tentative: false,
    conflicting: false,

    fearful: false,
    hurtful: false,
    mean: false,
    joyful: false,
    love: false,
    relaxed: false,
    agitated: false,
    awful: false,
    negative: false,
    unhappy: false,
    annoying: false,

    tense: false,
    weary: false,
    exhausted: false,
    frustrated: false,
    excited: false,
    inspired: false,
    awe: false,
    surprise: false,
    interested: false,
    irritable: false,
    optimistic: false,
    satisfaction: false,
    bright: false,
    detached: false,
    trust: false,
    apathetic: false,
    bored: false,
    drowsy: false,
    hopeful: false,
    regretful: false,
    sad: false,
    blissful: false,
    happy: false,
    downhearted: false,
    ecstatic: false,
    //////////////
  });

  const CheckboxList = ({ sensations, setSensations }) => {
    return (
      <>
        {Object.entries(sensations).map(([sensation, isChecked]) => (
          <div className="col-md-2" key={sensation}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isChecked}
                onChange={() => {
                  setSensations((prevSensations) => ({
                    ...prevSensations,
                    [sensation]: !isChecked,
                  }));
                }}
              />
              <label className="form-check-label">{sensation.charAt(0).toUpperCase() + sensation.slice(1)}</label>
            </div>
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (selectedAppointmentId && selectedAppointmentId != "") {
      getBody();
    }
  }, []);

  const getBody = async () => {
    dispatch(setSaveIndicator(true));
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/reports/detail?id=" + selectedAppointmentId, options);
    if (Response.data.success) {
      setInitial(false);

      if (Response.data.result && Response.data.result.physicalSensations) {
        setPhysicalSensations(Response.data.result.physicalSensations);
        setMentalSensations(Response.data.result.mentalSensations);
        setComment(Response.data.result.comment);

        dispatch(setMentalSensation(Response.data.result.physicalSensations));
        dispatch(setPhysicalSensation(Response.data.result.mentalSensations));

        dispatch(setSaveIndicator(false));
      } else {
        setPhysicalSensations(Response.data.result.physicalSensations);
        setMentalSensations(Response.data.result.mentalSensations);
        setComment(Response.data.result.comment);

        dispatch(setMentalSensation(Response.data.result.physicalSensations));
        dispatch(setPhysicalSensation(Response.data.result.mentalSensations));

        dispatch(setSaveIndicator(false));
      }
    } else {
      dispatch(setSaveIndicator(false));
      toast.error(Response.data.message);
    }
  };

  const syncBody = async () => {
    dispatch(setSaveIndicator(true));
    const options = {
      headers: { authorization: token },
    };

    const urlString = window.location.search;
    const urlParams = new URLSearchParams(urlString);
    const id = urlParams.get("id");

    const Response = await axios.post(
      process.env.REACT_APP_API_URL + "app/appointment/sync?practitioner=" + userData._id + "&patient=" + id + "&id=" + selectedAppointmentId,
      {
        physicalSensations: physicalSensations,
        mentalSensations: mentalSensations,
        comment: comment,
      },
      options
    );
    dispatch(setSaveIndicator(false));
    dispatch(setSelectedAppointment(Response.data.id));
  };

  useEffect(() => {
    // if (!initial) {
    //   syncBody();
    //   dispatch(setMentalSensation(mentalSensations));
    //   dispatch(setPhysicalSensation(physicalSensations));
    // }

    syncBody();
    dispatch(setMentalSensation(mentalSensations));
    dispatch(setPhysicalSensation(physicalSensations));
  }, [physicalSensations, mentalSensations, initial, comment]);

  return (
    <>
      <div className="mb-5 mt-5">
        <div className="h5 mb-4">I feel the following physical sensations. Check all that apply.</div>
        <div className="row">
          <CheckboxList sensations={physicalSensations} setSensations={setPhysicalSensations} />
        </div>

        <hr />

        <div className="h5 mb-4">As a result of the physical sensations, they are causing me to be mentally:</div>
        <div className="row">
          <CheckboxList sensations={mentalSensations} setSensations={setMentalSensations} />
        </div>
        <hr />
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Comments (Optional)</label>
          <textarea class="form-control" rows="2" placeholder="Start typing" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
        </div>
      </div>
    </>
  );
}
