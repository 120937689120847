import React, { useContext, useState } from "react";
import { ProfileDropdown } from "../components/header";
import { Button, Section, Box } from "../components/elements";
import { DrawerContext } from "../context/Drawer";
import { Logo } from "../components";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import data from "../data/master/header.json";
import axios from "axios";
import toast from "react-hot-toast";
import { setUserData, setSelectedCompany } from "../actions";
import { connect } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Table, Thead, Tbody, Th, Tr, Td } from "../components/elements/Table";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const mapDispatchToProps = {
  setUserData,
  setSelectedCompany,
};
const mapStateToProps = (state) => ({
  counter: state.counter.counter,
});

const Profile = ({ setUserData, setSelectedCompany }) => {
  const navigate = useNavigate();
  const { userData, loginStatus, token, companyList, selectedCompany } = useSelector((state) => state.counter);
  const { drawer, toggleDrawer } = useContext(DrawerContext);
  const [scroll, setScroll] = useState("fixed");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [data2, setData2] = useState([]);
  const [slug, setSlug] = useState("");
  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 0) setScroll("sticky");
    else setScroll("fixed");
  });

  useEffect(() => {
    fetchUserDetails();
  }, []);
  //details

  async function fetchUserDetails() {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/details?id=" + userData._id, options);
    if (Response.data.status == "success") {
      setUserData(Response.data.result);
      setSlug(Response.data.result.slug);
      if (selectedCompany && selectedCompany.practitioner) {
        fetchCompany(selectedCompany.practitioner);
      }
    } else {
      toast.error(Response.data.message);
    }
  }

  async function fetchCompany(id) {
    const options = {
      headers: { authorization: token },
    };

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/details?id=" + id, options);
    if (Response.data.status == "success") {
      setSlug(Response.data.result.slug);
    } else {
      toast.error(Response.data.message);
    }
  }

  return (
    <>
      <Section as="header" className={`mc-header text-white ${scroll}`}>
        <Logo src={data?.logo.src} alt={data?.logo.alt} name={"   "} href={"/crm"} />
        <Box className="mc-header-group">
          <Box className="mc-header-left">
            <Button icon={drawer ? "menu_open" : "menu"} className="mc-header-icon toggle" onClick={toggleDrawer} />
          </Box>

          <Box className="mc-header-right">
            {companyList && companyList.length > 0 && (
              <>
                <a href={"#"} className="header_button text-white" onClick={() => setShow2(true)}>
                  <i class="material-icons">meeting_room</i>
                  <span>{selectedCompany.name} </span>
                </a>
              </>
            )}

            {loginStatus && (
              <>
                {window.location.hostname == "account.atsdhst.com" && (
                  <>
                    <a href={"https://" + slug + ".atsdhst.com"} target="_blank" className="header_button text-white">
                      <i class="material-icons">link</i> <span>Your website link</span>
                    </a>
                  </>
                )}
              </>
            )}

            {loginStatus && <>{window.location.hostname == "account.atsdhst.com" && <ProfileDropdown name={userData.fname + " " + userData.lname} image={data.profile.image} username={userData.fname + " " + userData.lname} dropdown={data.profile.dropdown} />}</>}
          </Box>
        </Box>
      </Section>
      <Offcanvas show={show2} onHide={handleClose2} placement={"bottom"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Choose Your Company</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <Box className="mc-table-responsive">
                <Table className="mc-table">
                  <Thead className="mc-table-head primary">
                    <Tr>
                      <Th>Name</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="mc-table-body even">
                    {companyList?.length > 0 &&
                      companyList.map((item, key) => (
                        <Tr key={key}>
                          <Td>{item.name}</Td>

                          <Td>
                            <Box className="mc-table-action">
                              <Button
                                title="Delete"
                                className="view p-3"
                                onClick={() => {
                                  setShow2(false);
                                  setSelectedCompany(item);
                                  navigate("/crm");
                                }}
                              >
                                Use
                              </Button>
                            </Box>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </Box>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
