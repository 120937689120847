import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { CardLayout } from "./../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor, { buttonList } from "suneditor-react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "./../../components";
import { MultiSelect } from "react-multi-select-component";
export default function ProductList() {
  const navigate = useNavigate();
  const { userData, token, selectedCompany } = useSelector((state) => state.counter);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [Saving, setSaving] = useState(false);
  const [lastActivity, setLastActivity] = useState("");
  useEffect(() => {
    fetchData(1);
  }, []);

  async function fetchData(pageNumber) {
    const options = {
      headers: { authorization: token },
    };

    const params = {
      practitioner: selectedCompany && selectedCompany._id ? "" : userData._id,
      company: selectedCompany && selectedCompany ? selectedCompany._id : "",
    };

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    const Response = await axios.get(process.env.REACT_APP_API_URL + "app/patient/all?" + queryString, options);
    if (Response && Response.data.success) {
      if (Response.data.result) {
        var users = [];
        for (let i = 0; i < Response.data.result.length; i++) {
          users.push({ label: Response.data.result[i].name + "(" + Response.data.result[i].email + ")", value: Response.data.result[i].email });
        }
        setUsers(users);
      }
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  async function handleOverview() {
    if (selected.length == 0 && lastActivity == "") {
      toast.error("Select atleast one recipient");
      return;
    }

    if (!subject && subject == "") {
      toast.error("Enter subject");
      return;
    }

    if (!message && message == "") {
      toast.error("Enter message");
      return;
    }

    setSaving(true);
    const options = {
      headers: { authorization: token },
      "Content-Type": "application/x-www-form-urlencoded",
    };

    var insertData = [];

    insertData["lastActivity"] = lastActivity;
    insertData["subject"] = subject;
    insertData["message"] = message;
    insertData["name"] = selectedCompany && selectedCompany.name ? "" : userData.fname + " " + userData.lname;
    insertData["replyTo"] = selectedCompany && selectedCompany.email ? "" : userData.email;
    insertData["company"] = selectedCompany ? selectedCompany._id : null;
    insertData["practitioner"] = userData._id;

    const emailValues = selected.map((entry) => entry.value);
    const recipient = emailValues.join(", ");
    insertData["recipient"] = recipient;

    const Response = await axios.post(process.env.REACT_APP_API_URL + "app/patient/email", qs.stringify(insertData), options);
    if (Response.data.status == "success") {
      setSaving(false);
      setSelected([]);
      setSubject("");
      setMessage("");

      toast.success(Response.data.message);
    } else {
      setSaving(false);
      toast.error(Response.data.message);
    }
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Marketing"}></Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={12}>
                <div className="form-group mt-3">
                  <label>Recipient</label>
                  <MultiSelect hasSelectAll={false} options={users} value={selected} onChange={setSelected} labelledBy="Select" />
                </div>
                <div className="mt-3">OR</div>
                <div className="form-group mt-3">
                  <label>Recipient</label>
                  <div className="form-group">
                    <select className="form-control" value={lastActivity} onChange={(e) => setLastActivity(e.target.value)}>
                      <option value={""}> Filter by last activity </option>
                      <option value={"1"}> 3 Month </option>
                      <option value={"2"}> 6 Month </option>
                      <option value={"3"}> More then 6 month </option>
                    </select>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label>Subject</label>
                  <input type="text" className="form-control" value={subject} onChange={(e) => setSubject(e.target.value)} />
                </div>
                <div className="form-group mt-3">
                  <label>Message</label>
                  <SunEditor
                    setOptions={{
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["paragraphStyle", "blockquote"],
                        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                        ["fontColor", "hiliteColor", "textStyle"],
                        ["removeFormat"],
                        "/", // Line break
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["table", "link" /** ,'math' */], // "image", "video", "audio" You must add the 'katex' library at options to use the 'math' plugin.
                        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                        ["fullScreen"], //"showBlocks", "codeView"
                        // ["preview", "print"],
                        // ["save", "template"],
                        /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                      ],
                    }}
                    onChange={setMessage}
                    setContents={message}
                    height={400}
                  />
                </div>
                <button type="button" className="btn btn-primary mt-5" onClick={() => handleOverview()}>
                  {Saving ? <Spinner as="span" animation="grow" size="sm" /> : <>Send Emial</>}
                </button>
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
